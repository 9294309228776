
const dataTitulos =[
  {
    id: 1,
    imagen: require('../../asset/img/titulo_henry.jpg'),
    nombre: 'Full Stack Web Developer'
  },
  // {
  //   id: 2,
  //   imagen: require('../../asset/img/titulo_henry.jpg'),
  //   nombre: 'Full Stack Web Developer'
  // },
  // {
  //   id: 3,
  //   imagen: require('../../asset/img/titulo_henry.jpg'),
  //   nombre: 'Full Stack Web Developer'
  // },
  // {
  //   id: 4,
  //   imagen: require('../../asset/img/titulo_henry.jpg'),
  //   nombre: 'Full Stack Web Developer'
  // }
]

export default dataTitulos;